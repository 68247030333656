













































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { Article, FileObject } from "@/core/models";
import { getLinks, urlRegex } from "@/core/utils/getLinks";
import ArticleActions from "./components/ArticleActions.vue";
import LinkPreview from "@/components/common/LinkPreview.vue";
import { VideoPlayer, FileDisplay } from "@/components/common";

@Component({
  components: {
    VideoPlayer,
    ArticleActions,
    FileDisplay,
    LinkPreview,
  },
})
export default class ArticleDetails extends Vue {
  @Getter("news/loading") loading!: boolean;
  @Getter("news/getArticles") articles!: Article[];
  @Getter("news/retrieved") hasRetrieved!: boolean;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;
  @Action("news/getArticles") getArticles!: () => Promise<void>;

  articleID = 0;
  get article() {
    return this.articles.find(a => a.ID == this.articleID);
  }
  get files() {
    return this.article!.Files;
  }
  get banner() {
    if (!this.article) return null;
    if (this.article.BannerImage) return this.article.BannerImage;
    const pred = (f: FileObject) => f.Type.includes("image/");
    const firstImage = this.article.Files.filter(pred)[0];
    if (!firstImage) return null;
    return firstImage;
  }

  openImage() {
    if (this.article?.BannerImage?.URI)
      window.open(this.article.BannerImage.URI, "_blank");
  }

  parseParagraph(p: string) {
    let matches = p.matchAll(urlRegex);
    for (let match of matches) {
      let url = match[0];
      let href = url;
      if (!href.startsWith("http")) href = "https://" + href;
      let aTag = `<a href="${href}" target="_blank">${url}</a>`;
      p = p.replace(url, aTag);
    }
    return p;
  }

  links: string[] = [];
  @Watch("$route", { immediate: true })
  routeChanged() {
    if (!this.$route.path.startsWith("/news/article/")) return;
    this.articleID = Number(this.$route.params.id);
  }
  @Watch("hasRetrieved", { immediate: true })
  retrievedArticles() {
    if (this.hasRetrieved) this.articleID = Number(this.$route.params.id);
  }
  @Watch("article", { immediate: true })
  gotArticle() {
    if (this.article?.Body) this.links = getLinks(this.article.Body);
  }

  mounted() {
    if (!this.hasRetrieved) this.getArticles();
  }
}
